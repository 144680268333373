import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getInlineFontStyle, getPreconfiguredFontClassnames } from "../../../utils/fontOptions.util";

import { PageLinkContext } from "../page-link/pageLink.util";
import { StringUtil } from "../../../utils/string.util";
import { WidgetOptions } from "./dropDownMenu.types";
import { wrapProps } from "../../../i18n";

interface DropDownMenuPropsBase {
    children: JSX.Element[];
    label: string;
    dropdownType: string;
    className?: string;
    options: WidgetOptions;
    isActive: boolean;
}

type DropDownMenuProps = DropDownMenuPropsBase;

interface DropDownMenuState {
    dropdownOpen: boolean;
    isModalOpen: boolean;
}

export class DropDownMenuBase extends React.PureComponent<DropDownMenuProps, DropDownMenuState> {
    public dropDownComp: React.ReactInstance;

    constructor(props: DropDownMenuProps) {
        super(props);
        this.state = {
            dropdownOpen: false,
            isModalOpen: false,
        };
    }

    public render(): JSX.Element | null {
        const { label, options, children, className, isActive } = this.props;
        // usage of different icons here

        /* jscpd:ignore-start */
        const iconClassName = options?.iconProperties ? (
            <FontAwesome
                name={options.iconProperties}
                // eslint-disable-next-line max-len
                className={`fontawesome-${options.iconProperties} icon ${
                    options.iconColor && options.iconColor.indexOf("theme") > -1 ? `color-${options.iconColor}` : options.iconColor && options.iconColor.indexOf("rgba") === -1 ? options.iconColor : ""
                }`}
                style={{ color: options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
            />
        ) : undefined;
        const showIcon = options && options.showIcons ? iconClassName : "";
        if (options.dropdownType !== "modal") {
            return (
                <Dropdown
                    nav
                    className={classNames("menu-dropdown-link", className, { "hover-type": !!this.props.dropdownType }, { "active-menu": !isActive })}
                    isOpen={this.state.dropdownOpen}
                    toggle={
                        !options.disableToggle
                            ? this.toggle
                            : () => {
                                  return;
                              }
                    }
                >
                    <DropdownToggle nav caret className={classNames(getPreconfiguredFontClassnames(options.fontConfiguration))} style={getInlineFontStyle(options.fontConfiguration)}>
                        {showIcon} {label}
                    </DropdownToggle>
                    <DropdownMenu className={classNames("menu-dropdown-popup", { "right-align": options.contentRight, ["menu-width-" + `${options.menuWidth}`]: !!options.menuWidth })}>
                        {options.dropdownType === "toggle" && options.closeIcon ? (
                            <span className={"close-icon"} onClick={this.toggle}>
                                <FontAwesome name="close" />
                            </span>
                        ) : null}
                        <div className="menu-dropdown-wrap">{<PageLinkContext.Provider value={{ isInsideDropdownMenu: true }}>{children}</PageLinkContext.Provider>}</div>
                    </DropdownMenu>
                </Dropdown>
            );
        }
        return (
            <div onClick={this.toggleModal} className={classNames(getPreconfiguredFontClassnames(options.fontConfiguration))} style={getInlineFontStyle(options.fontConfiguration)}>
                {showIcon} {label}
                {this.renderModal()}
            </div>
        );
    }
    /* jscpd:ignore-end */

    private toggle = () => {
        if (window.innerWidth > 820) {
            if (this.props.dropdownType === "toggle") {
                this.setState((state) => ({
                    ...state,
                    dropdownOpen: !state.dropdownOpen,
                }));
            }
        } else {
            this.setState((state) => ({
                ...state,
                dropdownOpen: !state.dropdownOpen,
            }));
        }
    };

    private toggleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    private renderModal = () => {
        const { isModalOpen } = this.state;
        const { options } = this.props;
        return (
            <Modal id={StringUtil.convertToDocumentQuerySelectable(options.modalName)?.toLowerCase()} isOpen={isModalOpen} size="full" toggle={this.toggleModal}>
                <ModalHeader toggle={this.toggleModal}></ModalHeader>
                <ModalBody className="dropdown-modal">
                    <div className="menu-dropdown-wrap">{this.props.children}</div>
                </ModalBody>
            </Modal>
        );
    };
}

export const DropDownMenu = wrapProps<DropDownMenuPropsBase>(DropDownMenuBase);
