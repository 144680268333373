import * as React from "react";

export interface PageLinkContextType {
    isInsideDropdownMenu: boolean;
    isInsideGroup?: boolean;
}

/**
 * This context is needed so we can identify inside the PageLink widget if it has an ancestor DropdownMenuWidget or GroupWidget
 */
export const PageLinkContext: React.Context<PageLinkContextType> = React.createContext<PageLinkContextType>({
    isInsideDropdownMenu: false,
    isInsideGroup: false,
});
