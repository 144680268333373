import * as Icons from "../../page/icons";
import * as React from "react";

import { FontConfigurationSpec, defaultFontConfigurationValues } from "../../../utils/menufont.util";
import { FormSpec, IconColoring, localized, multiSelectStylePicker } from "../../../form-specs";
import { LocalizedMenuLink, WidgetOptions } from "./dropDownMenu.types";
import { MenuWidgetSpec, Widget, isPageWidget, reportWidgetRenderError } from "../../";
import { Site, WithId } from "@maxxton/cms-api";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DropDownMenu } from "./DropDownMenu";
import { Identity } from "../../../containers/Identity";
import { WidgetGroup } from "../../widget.enum";
import { autocompleteSiteSpec } from "../../../form-specs/models/autocompleteSite";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";
import { pageLink } from "../../../routing";
import { pageSpec } from "../../../form-specs/models/page";

const iconList = Object.keys(Icons.default).map((key: any) => ({ label: (Icons.default as any)[key], value: key }));

const TARGETS = ["menu-dropdown"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dropdownmenu-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "dropdownmenuWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "dropdownmenuWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "general"),
                    properties: [
                        [
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "label",
                                        label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "label"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "dropdownType",
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "isMouseHoverOpen"),
                                type: "select",
                                default: "toggle",
                                optionList: [
                                    { value: "toggle", label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "toggle") },
                                    { value: "hover", label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "hover") },
                                    { value: "modal", label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "modal") },
                                ],
                            },
                            {
                                variable: "modalName",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "name"),
                                type: "text",
                                visible: (options: WidgetOptions) => options.dropdownType === "modal",
                            },
                            {
                                variable: "closeIcon",
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "closeBtn"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.dropdownType === "toggle",
                            },
                            {
                                variable: "disableToggle",
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "disableToggle"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.dropdownType === "toggle",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "menuItemVisibility"),
                                variable: "hidden",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "contentRight"),
                                variable: "contentRight",
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "breadcrumbOptions"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "useAsBreadcrumb"),
                                variable: "useAsBreadcrumb",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "siteId"),
                                variable: "siteId",
                                type: "autocomplete",
                                refType: autocompleteSiteSpec,
                                visible: (item: any) => item.useAsBreadcrumb,
                            },
                            {
                                variable: "pageLinkId",
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "pageLinkLabel"),
                                type: "autocomplete",
                                refType: pageSpec,
                                dependsOnSiteSpec: "siteId",
                                visible: (item: any) => item.useAsBreadcrumb,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContent, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            FontConfigurationSpec<WidgetOptions, keyof WidgetOptions>({ variable: "fontConfiguration" }),

                            {
                                variable: "changeMenuWidth",
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "changeMenuWidth"),
                                type: "checkbox",
                            },
                            {
                                variable: "menuWidth",
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "menuWidth"),
                                type: "number",
                                default: 15,
                                visible: (options: WidgetOptions) => options.changeMenuWidth,
                            },
                            {
                                variable: "showIcons",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconTitle"),
                                type: "checkbox",
                            },
                            {
                                variable: "iconProperties",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconProperty"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.showIcons,
                                optionList: iconList,
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconColor"),
                                type: "dual-color",
                                default: "color-brand-alt" as IconColoring,
                                visible: (options: WidgetOptions) => options.showIcons,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

async function isActivePageLink(widget: Widget<any>, context: CMSProvidedProperties, allSites?: Array<Site & WithId>): Promise<boolean> {
    const childSpec = widget.spec;
    if (childSpec.id === "pagelink") {
        const { siteId, pageId } = widget.options;
        let site: (Site & WithId) | null | undefined;
        if (allSites && allSites.length > 0) {
            site = allSites.find((item) => item._id === siteId);
        }
        if (site) {
            const url = await pageLink({ site, pageId, context });
            if (url && url !== "/" && context.location.pathname === url) {
                return true;
            }
        }
    }
    if (widget.children && widget.children.length > 0) {
        let isActive = false;
        await Promise.all(
            widget.children.map(async (child) => {
                if (!isActive) {
                    isActive = await isActivePageLink(child, context, allSites);
                }
            })
        );
        return isActive;
    }
    return false;
}

export const dropDownMenuWidget: MenuWidgetSpec<WidgetOptions> = {
    id: "dropdownmenu",
    type: "menu",
    widgetGroup: WidgetGroup.OTHER,
    name: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "dropdownmenuWidget"),
    description: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "dropdownmenuWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        localized: [],
        styleIds: [],
        dropdownType: "toggle",
        pageLinkId: "",
        siteId: "",
        useAsBreadcrumb: false,
        contentRight: false,
        useInitialContent: false,
        showIcons: false,
        iconProperties: "",
        iconColor: "color-brand",
        iconPosition: "top",
        closeIcon: false,
        hidden: false,
        disableToggle: false,
        changeMenuWidth: false,
        menuWidth: 15,
        fontConfiguration: defaultFontConfigurationValues,
        modalName: "",
    }),
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties, allSites?: Array<Site & WithId>, sitemapWidgetOptions?) {
        const { currentLocale, site } = context;
        let isActive = false;
        // eslint-disable-next-line max-len
        const children: JSX.Element[] = await Promise.all(
            widget.children
                .filter((item) => !item.options.hidden)
                .map(async (child, index) => {
                    const childSpec = child.spec;
                    if (child.children && child.children.length > 0) {
                        await Promise.all(
                            child.children.map(async (element) => {
                                if (!isActive) {
                                    isActive = await isActivePageLink(element, context, allSites);
                                }
                            })
                        );
                    }
                    if (isPageWidget(childSpec)) {
                        return (
                            childSpec
                                .render(child, context, sitemapWidgetOptions, undefined, undefined)
                                .then((elem) => <Identity key={child._id}>{elem}</Identity>)
                                // silent the failure of the widget and report error in logstash
                                .catch((err) => {
                                    reportWidgetRenderError(widget, err, childSpec, context);
                                    return <div key={index} />;
                                })
                        );
                    }
                    return (
                        (childSpec as MenuWidgetSpec<any>)
                            .render(child, context, allSites, sitemapWidgetOptions)
                            .then((elem) => <Identity key={child._id}>{elem}</Identity>)
                            // silent the failure of the widget and report error in logstash
                            .catch((err) => {
                                reportWidgetRenderError(widget, err, childSpec, context);
                                return <div key={index} />;
                            })
                    );
                })
        );
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const localizedMenuLink: LocalizedMenuLink | null = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localized });
        const label = localizedMenuLink?.label || "";
        const { dropdownType } = widget.options;
        const { options } = widget;
        return <DropDownMenu key={widget._id} label={label} children={children} options={options} className={styleClasses} dropdownType={dropdownType} isActive={isActive} />;
    },
};
