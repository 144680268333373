import * as classNames from "classnames";

import { FontConfigurationOptions, MenuFontTextStyle } from "./menufont.util";

// Menu's
export const getPreconfiguredFontClassnames = (options: FontConfigurationOptions) => {
    if (options) {
        return classNames({
            [`${options.menuFontSize}`]: !options.overwriteFontSize,
            ["font-size-" + `${options.fontSizeInPixels}`]: options.overwriteFontSize && !!options.menuFontSize,
            [`color-${options.menuFontColor}`]: options.menuFontColor?.includes("theme"),
            ["row-bordered"]: options.menuFontStyle === MenuFontTextStyle.BORDER_LINE,
            [`background-color-${options.menuFontBackgroundColor}`]: options.menuFontBackgroundColor?.includes("theme") && options.menuFontStyle === MenuFontTextStyle.BACKGROUND_COLOR,
            ["bold"]: options.menuStyleBold,
            ["italic"]: options.menuStyleItalic,
        });
    }
    return "";
};

export const getInlineFontStyle = (options: FontConfigurationOptions) => {
    if (options) {
        return {
            ...(options.menuFontColor?.includes("rgba") && { color: options.menuFontColor }),
            ...(options.menuFontBackgroundColor?.includes("rgba") && options.menuFontStyle === MenuFontTextStyle.BACKGROUND_COLOR && { background: options.menuFontBackgroundColor }),
        };
    }
    return {};
};
