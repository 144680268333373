import { GroupOptions, InputSpecGroup } from "../form-specs/index";

import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export interface FontConfigurationOptions {
    menuFontSize: MenuFontSize;
    overwriteFontSize: boolean;
    fontSizeInPixels: string;
    menuFontColor: string;
    menuFontStyle: string;
    menuFontBackgroundColor: string;
    menuBorderWidth: number;
    menuStyleItalic: boolean;
    menuStyleBold: boolean;
}

export enum MenuFontTextStyle {
    DEFAULT = "default",
    BACKGROUND_COLOR = "background-text",
    BORDER_LINE = "row-bordered",
}

export enum MenuStyles {
    BOLD = "Use Bold?",
    ITALIC = "Use Italic?",
}

export type MenuFontSize =
    | "default-font-size"
    | "micro-font-size"
    | "xx-small-font-size"
    | "x-small-font-size"
    | "small-font-size"
    | "h6-text"
    | "h5-text"
    | "h4-text"
    | "h3-text"
    | "h2-text"
    | "h1-text";

export const I18N_FONT_SIZE = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontSize");
export const I18N_OVERWRITE_FONTSIZE = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "overwriteFontSize");
export const I18N_FONTSIZE_IN_PIXELS = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontsizeInPixels");
export const I18N_MENU_FONT_COLOR = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "menuFontColor");
export const I18N_MENU_FONT_STYLE = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "menuFontStyle");
export const I18N_MENU_FONT_BACKGROUND_COLOR = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "menuFontBackgroundColor");
export const I18N_MENU_BORDER_WIDTH = getI18nLocaleObject(namespaceList.admin, "borderWidth");
export const I18N_MENU_TEXT_BACKGROUND = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "textBackground");

/* eslint-disable max-lines-per-function */
export const FontConfigurationSpec = <E, P extends keyof E>(options: GroupOptions<E, P>): InputSpecGroup<E, P, FontConfigurationOptions, keyof FontConfigurationOptions> => {
    const { variable, visible } = options;
    return {
        variable,
        visible,
        type: "group",
        specs: [
            {
                variable: "menuFontSize",
                label: I18N_FONT_SIZE,
                default: "default-font-size" as MenuFontSize,
                type: "select",
                visible: (options: FontConfigurationOptions) => !options?.overwriteFontSize,
                optionList: [
                    {
                        value: "default-font-size",
                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                    },
                    {
                        value: "micro-font-size",
                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                    },
                    {
                        value: "xx-small-font-size",
                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                    },
                    {
                        value: "x-small-font-size",
                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                    },
                    {
                        value: "small-font-size",
                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                    },
                    {
                        value: "h6-text",
                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                    },
                    {
                        value: "h5-text",
                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                    },
                    {
                        value: "h4-text",
                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                    },
                    {
                        value: "h3-text",
                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                    },
                    {
                        value: "h2-text",
                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                    },
                    {
                        value: "h1-text",
                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                    },
                ],
            },
            {
                variable: "overwriteFontSize",
                label: I18N_OVERWRITE_FONTSIZE,
                type: "checkbox",
            },
            {
                variable: "fontSizeInPixels",
                label: I18N_OVERWRITE_FONTSIZE,
                type: "text",
                visible: (options: FontConfigurationOptions) => options?.overwriteFontSize,
            },
            {
                variable: "menuFontColor",
                label: I18N_MENU_FONT_COLOR,
                type: "dual-color",
                default: "default",
            },
            {
                variable: "menuFontStyle",
                label: I18N_MENU_FONT_STYLE,
                type: "select",
                default: "default",
                optionList: [
                    {
                        value: "default",
                        label: MenuFontTextStyle.DEFAULT,
                    },
                    {
                        value: "background-text",
                        label: MenuFontTextStyle.BACKGROUND_COLOR,
                    },
                    {
                        value: "row-bordered",
                        label: MenuFontTextStyle.BORDER_LINE,
                    },
                ],
            },
            {
                variable: "menuFontBackgroundColor",
                label: I18N_MENU_TEXT_BACKGROUND,
                type: "dual-color",
                default: "background-gray",
                visible: (options: FontConfigurationOptions) => options?.menuFontStyle === MenuFontTextStyle.BACKGROUND_COLOR,
            },
            {
                variable: "menuBorderWidth",
                label: I18N_MENU_BORDER_WIDTH,
                type: "number",
                visible: (options: FontConfigurationOptions) => options?.menuFontStyle === MenuFontTextStyle.BORDER_LINE,
            },
            {
                variable: "menuStyleItalic",
                label: MenuStyles.ITALIC,
                type: "checkbox",
            },
            {
                variable: "menuStyleBold",
                label: MenuStyles.BOLD,
                type: "checkbox",
            },
        ],
    };
};

export const defaultFontConfigurationValues: FontConfigurationOptions = {
    menuFontSize: "default-font-size",
    overwriteFontSize: false,
    fontSizeInPixels: "",
    menuFontColor: "Default",
    menuFontStyle: "default",
    menuFontBackgroundColor: "background-gray",
    menuBorderWidth: 1,
    menuStyleItalic: false,
    menuStyleBold: false,
};
